<template>
    
    <searchBody>
        <!-- <TreeSelect v-model="value" :data="treedata" v-width="500" ></TreeSelect>
        <search class="searchbar" :show-create="false" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search> -->
        <div style="height: 50px;"></div>
        <Button class="addbtn" type="success" @click="onCreate" style="top: -4px;">新增问卷</Button>
        <Card class="table_container" ref="table_container">
            <Table :key="tableKey" stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="status">
                    <!-- <span :class="'now_state_' + row.status">{{ row.status == 1 ? '开启' : '关闭' }}</span> -->
                        <!-- <span v-if="row.status == 1">
                            <i-switch  v-model="statusSwitch1" @on-change="changeSwitch(row,$event)" />
                        </span>
                        <span v-if="row.status == 0">
                            <i-switch  v-model="statusSwitch" @on-change="changeSwitch(row,$event)" />
                        </span> -->
                        <el-switch v-model="row.status"  :active-value="'1'" :inactive-value="'0'" @change="changeStatus($event,row)">
						</el-switch>
                </template>
                <template slot-scope="{ row }" slot="title">
                    <!-- <span :class="'now_state_' + row.status">{{ row.status == 1 ? '开启' : '关闭' }}</span> -->
                        <span>
                           {{ row.title }}<Tag color="primary" v-if="row.id == 1">小程序调查问卷</Tag>
                        </span>
                       
                </template>
                
                <template slot-scope="{ row }" slot="process">
                    <!-- <span v-if="row.id ==1">进行中</span> -->
                    <span v-if="row.process == 2 ">已结束</span>
                    <span v-if="row.process == 1 ">调研中</span>
                    <span v-if="row.process == 0 ">未开始</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="success" size="small" ghost @click="onView(row)">详情</Button>
                        <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                        <Button type="primary" size="small" ghost @click="showUrl(row)">链接</Button>
                        <Button type="primary"  size="small" ghost @click="exportDown(row)">下载报表</Button>
                        <Button type="primary" v-if="(row.process == 0 || row.process == 1) && row.id!=1" size="small" ghost @click="onDelete(row)">结束</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <Modal
        v-model="modal"
        title="结束"
        :loading="loading"
        @on-ok="asyncOK">
        <p>您是否确认结束此调研？</p>
        </Modal>
        <Modal
        v-model="urlShow"
        title="链接"
        :loading="loading">
        <p>{{ defaultUrl  }}</p>
        <Button type="primary" style="margin-top: 10px;" ghost @click="copy()">复制</Button>
        </Modal>
        <Modal :closable="false" v-model="showCreate" :title="title" width="500" :loading="ajaxLoading">
            <div class="form_item">
                <div class="form_label must_input">新密码</div>
                <Input  v-model.trim="formData.new_password" :maxlength="16" show-word-limit></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">确认密码</div>
                <Input v-model.trim="formData.con_password" :maxlength="16" show-word-limit></Input>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelCreate">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button>
            </div>
        </Modal>
        <Modal
        v-model="ztmodal"
        title="暂停代理"
        :loading="loading"
        @on-ok="surePause">
        <p>您是否确认暂停{{ztname}}的代理？</p>
        </Modal>
        <Modal :closable="false" v-model="showCreate" :title="title" width="500" :loading="ajaxLoading">
            <div class="form_item">
                <div class="form_label must_input">新密码</div>
                <Input  v-model.trim="formData.new_password" :maxlength="16" show-word-limit></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">确认密码</div>
                <Input v-model.trim="formData.con_password" :maxlength="16" show-word-limit></Input>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelCreate">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button>
            </div>
        </Modal>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqMerchant,paperReportExport } from '@/lib/request/auth2';
import { getAgentList,modPasswode,deleteItem,cancel,getPaperList,paperEnd,paperPublish} from '@/lib/request/auth';
import { consoleJson, isNotNullObject } from '@/lib/util';

export default {
    name: 'serviceProviderList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            statusSwitch:false,
            statusSwitch1:true,
            urlShow:false,
            value:'',
            treedata: [
                    {
                        title: 'parent1',
                        expand: true,
                        value: 'parent1',
                        selected: false,
                        checked: false,
                        children: [
                            {
                                title: 'parent 1-1',
                                expand: true,
                                value: 'parent1-1',
                                selected: false,
                                checked: false,
                                children: [
                                    {
                                        title: 'leaf 1-1-1',
                                        value: 'leaf1',
                                        selected: false,
                                        checked: false,
                                    },
                                    {
                                        title: 'leaf 1-1-2',
                                        value: 'leaf2',
                                        selected: false,
                                        checked: false,
                                    }
                                ]
                            },
                            {
                                title: 'parent 1-2',
                                expand: true,
                                value: 'parent1-2',
                                selected: false,
                                checked: false,
                                children: [
                                    {
                                        title: 'leaf 1-2-1',
                                        value: 'leaf3',
                                        selected: false,
                                        checked: false,
                                    },
                                    {
                                        title: 'leaf 1-2-1',
                                        value: 'leaf4',
                                        selected: false,
                                        checked: false,
                                    }
                                ]
                            }
                        ]
                    }
                ],
            ztname:'',
            ztid:'',
            ztmodal:false,
            deleteid:'',
            loading:false,
            modal:false,
            formData:{
                id:''
            },
            title:'',
            showCreate:false,
            searchs: [
                { id: hm.createId(), label: '身份ID', placeholder: '请输入身份ID', type: 'input', bind: 'id_card_no' },
                { id: hm.createId(), label: '姓名', placeholder: '请输入姓名', type: 'input', bind: 'name' },
                {
                    id: hm.createId(),
                    label: '状态',
                    placeholder: '选择状态',
                    type: 'select',
                    bind: 'status',
                    list: [
                        { id: '1', title: '代理中' },
                        { id: '0', title: '已暂停' },
                    ],
                },
            ],
            // searchs: [{ id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'name' }],
            tableKey: 'tableKey',
            dataColumn: [
                { key: 'id', title: '编号' },
                { slot: 'title', title: '问卷名称' },
                { key: 'created_at', title: '创建/更新时间' },
                { key: 'ques_count', title: '问卷问题' },
                { key: 'pv_count', title: '调研次数' },
                { key: 'uv_count', title: '调研人数' },
                { slot: 'status', title: '是否发布' },
                // { key: 'receiveNum', title: '接单情况（次）' },
                { slot: 'process', title: '状态' },
                { slot: 'manager', title: '操作' },
            ],
            query:{
                page:1,
                limit:10,
                pid:0
            },
            dataList: [],
            defaultUrl:''
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        changeStatus(name, row) {
			console.log(name, 'name');
			if (name == '1') {
				// var needData = {};
				// needData.id = row.id;
				// needData.status = 1;
                paperPublish({id:row.id,status:1}).then(res=>{
                    this.getList();
                })
			} else {
				// var needData = {};
				// needData.id = row.id;
				// needData.status = 0;
				paperPublish({id:row.id,status:0}).then(res=>{
                    this.getList();
                })
			}
		},
        exportDown(row){
            let params = window.structuredClone(this.params);
            params.export = 1;
            params.id = row.id;
            paperReportExport(params);
        },
        changeSwitch(row,status){
            console.log(status,'status');
            console.log(row.status,'row');
            if(row.status == 1){
                this.statusSwitch1 = false;
                this.statusSwitch = true;
                paperPublish({id:row.id,status:0}).then(res=>{
                    this.getList();
                })
            }else{
                this.statusSwitch = true;
                this.statusSwitch1 = false;
                paperPublish({id:row.id,status:1}).then(res=>{
                    this.getList();
                })
            }
           
            console.log(status,'changeSwitch')
        },
        copy(){
            const input = document.createElement('input');
            input.value = this.defaultUrl;
            // 将输入框添加到页面中
            document.body.appendChild(input);
            
            // 选中输入框的内容
            input.select();
            
            // 执行复制命令
            document.execCommand('copy');
            
            // 移除临时输入框
            document.body.removeChild(input);
            this.fadeAlert('复制成功');
        },
        showUrl(row){
            this.urlShow = true;
            this.defaultUrl = 'https://ql.i-lz.cn/h5/distribution/investigation.html?id=' + row.id 

        },
        surePause(){
            cancel({id:this.ztid}).then(res=>{
                this.fadeAlert('操作成功');
                this.ztmodal = false;
                this.getList();
            })
        },
        onPause(row){
            this.ztid = row.id;
            this.ztname = row.name;
            this.ztmodal = true;
        },
        onDelete(row){
            this.modal = true;
            this.deleteid = row.id;
        },
        asyncOK(){
            paperEnd({id:this.deleteid}).then(res=>{
                this.fadeAlert('修改成功');
                this.modal = false;
                this.getList();
            })
        },
        onConfirmCreate(){
            this.showAjaxLoading();
            modPasswode(this.formData)
                .then(res => {
                    this.showCreate = false;
                    this.fadeAlert('修改成功');
                    this.formData = {};
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });

        },
        showpassword(row){
            this.formData.id = row.id;
            this.title = '修改密码';
            this.showCreate = true;
        },
        onCancelCreate(){
            this.showCreate = false;
        },
        //获取列表数据
        getList() {
            this.showAjaxLoading();
            getPaperList(this.params)
                .then(res => {

                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    this.dataList = res.data;
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(_info = {}) {
            let info = window.structuredClone(_info);
            // if (isNotNullObject(info)) {
            //     info.userTel = info.userPhone;
            //     info.address_code = !!info.address_code ? info.address_code.split(',') : [];
            //     info.address_note = !!info.address ? info.address.split(';')[1] : null;
            //     delete info.userPhone;
            // } else {
            //     info.create = 1;
            // }
            // this.goName('distributionListAdd', info);
            this.$router.push({path:'/investigationAdd',query:{isEdit:true,id:info.id}})
        },
        onCreate(){
            this.$router.push({path:'/investigationAdd',query:{isEdit:false}},)
            // this.goName('distributionListAdd');
        },
        //详情
        onView(info = {}) {
            // let { id } = info || {};
            // this.goUrl('/serviceProviderView', { id });
            this.$router.push({path:'/investigationAdd',query:{isEdit:true,id:info.id,isDetail:true}},)
        },
    },
};
</script>
<style lang="less" scoped>
.table_container {
    & .ivu-switch span {
        font-size: revert;
    }
}
.addbtn{
    position: absolute;
    top: 38px;
    right: 24px;
}
</style>
